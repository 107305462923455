import React from "react";
import SEO from "../components/seo";

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <div className="page-center">Under Construction</div>
    <span className="bottom-right">
      <a href="/home-v1" target="_blank">
        See previous website
      </a>
    </span>
  </>
);

export default IndexPage;
